import React, { FunctionComponent, useMemo } from 'react';
import { Col, Container, Hidden, Row } from 'react-grid-system';
import { useLocation } from '@reach/router';

import './Categories.scss';

import website from 'config/website';

import { Breadcrumb } from 'components/Breadcrumb';
import { PrimaryLayout } from 'components/PrimaryLayout';
import { SEO } from 'components/SEO';
import { TextLink } from 'components/TextLink';
import * as routes from '../../utils/routes';
import Category from 'models/Category.model';
import Subcategory from 'models/Subcategory.model';
import { SideArticleList } from 'components/SideArticleList';
import { useAllContentfulArticle } from 'queries/article.queries';
import { sortArticleByCount } from 'utils/helpers';

type CategoriesProps = {
  pageContext: {
    categories: Category[];
    subCategories: Subcategory[];
    articleMonthlyViewCountMap: {
      [articleId: string]: number;
    };
  };
};
const Categories: FunctionComponent<CategoriesProps> = ({ pageContext }) => {
  const location = useLocation();

  const { categories, articleMonthlyViewCountMap } = pageContext;
  const allContentfulArticle = useAllContentfulArticle();

  const popularArticles = useMemo(() => {
    return sortArticleByCount(allContentfulArticle, articleMonthlyViewCountMap);
  }, [allContentfulArticle, articleMonthlyViewCountMap]);

  return (
    <PrimaryLayout>
      <SEO
        title={`All Categories | ${website.titleAlt}`}
        desc="All Categories"
        pathname={location.pathname}
      />
      <Container fluid className="categories-container">
        <Row nogutter>
          <Col lg={9}>
            <Col>
              <Breadcrumb
                paths={[
                  { label: 'Categories', link: routes.SiteRoutes.CATEGORIES },
                  { label: 'All', link: '' },
                ]}
              />
            </Col>
            {categories
              .filter(
                (category) =>
                  category.subcategories && category.subcategories.length > 0
              )
              .map((category) => (
                <Col key={category.id}>
                  <Row nogutter>
                    <div className="category-title">
                      <div>{category.name}</div>
                      <TextLink
                        variant="small"
                        to={`${routes.SiteRoutes.CATEGORIES}/${category.slug}`}
                        ariaLabel="Category link"
                      >
                        VIEW ALL
                      </TextLink>
                    </div>
                  </Row>
                  {(category.subcategories || [])
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((subCategory) => (
                      <Row nogutter className="subcategory-title">
                        <TextLink
                          variant="small"
                          ariaLabel={`Sub Category Link For ${category.name}`}
                          to={`${routes.SiteRoutes.CATEGORIES}/${category.slug}/${subCategory.slug}`}
                        >
                          {capitalizeEveryWord(subCategory.name)}
                        </TextLink>
                      </Row>
                    ))}
                </Col>
              ))}
          </Col>
          <Col lg={3}>
            <Hidden md sm xs>
              <SideArticleList
                title="Popular"
                icon="categories-title-prefix"
                allContentfulArticle={popularArticles}
              />
            </Hidden>
          </Col>
        </Row>
      </Container>
    </PrimaryLayout>
  );
};

export default Categories;

function capitalizeEveryWord(str: string) {
  let splitStr = str.split(' ');
  splitStr = splitStr.map((el) => {
    el = el.charAt(0).toUpperCase() + el.substring(1);
    return el;
  });
  return splitStr.join(' ');
}
